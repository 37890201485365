body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-continer-top{
  font-size: 20px;
  cursor: pointer;
  padding: 0 8px;
  border-radius: 50%;
  transition: background 0.3s;
}

header{
  box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

.modal-responsive-md {
  width: 80% !important; /* Default */
}

@media (min-width: 576px) {
  .modal-responsive-md {
    width: 60% !important;
  }
}

@media (min-width: 768px) {
  .modal-responsive-md {
    width: 40% !important;
  }
}

@media (min-width: 992px) {
  .modal-responsive-md {
    width: 40% !important;
  }
}

@media (min-width: 1200px) {
  .modal-responsive-md {
    width: 38% !important;
  }
}

@media (min-width: 1600px) {
  .modal-responsive-md {
    width: 35% !important;
  }
}